import React, { useState, useEffect, useRef } from 'react';
import css from './HoverMenu.module.css';
import NamedLink from '../../NamedLink/NamedLink';

const HoverMenu = ({ label, menuLinks, hoverMenuOpened, setHoverMenuOpened }) => {
  // const menuContentRef = useRef(null);

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const handleOutsideClick = event => {
  //       if (menuContentRef.current && !menuContentRef.current.contains(event.target)) {
  //         setHoverMenuOpened(null);
  //       }
  //     };

  //     document.addEventListener('click', handleOutsideClick);

  //     return () => {
  //       document.removeEventListener('click', handleOutsideClick);
  //     };
  //   }
  // }, []);

  return (
    <div className={css.wrapper}>
      <div
        className={css.label}
        onMouseEnter={() => setHoverMenuOpened(label)}
        onClick={() => setHoverMenuOpened(hoverMenuOpened === label ? null : label)}
      >
        {label}
      </div>
      {/* {hoverMenuOpened === label && (
        <div ref={menuContentRef} className={css.menuContent}>
          {menuLinks
            .filter(l => l?.name && l?.label)
            .map(l =>
              l.externalPage ? (
                <a className={css.menuLink} href={l.externalPage} target="_blank">
                  {' '}
                  {l.label}
                </a>
              ) : (
                <NamedLink
                  className={css.menuLink}
                  name={l.name}
                  key={l.name}
                  to={{ hash: l.hash }}
                >
                  {l.label}
                </NamedLink>
              )
            )}
        </div>
      )} */}
    </div>
  );
};

export default HoverMenu;
