export const sqftIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <g opacity="0.8">
      <path
        d="M7.36821 10.7227C7.22945 10.584 7.00387 10.584 6.8651 10.7227L4.62567 12.9622V10.9746C4.62567 10.7782 4.46627 10.6188 4.26987 10.6188C4.07346 10.6188 3.91406 10.7782 3.91406 10.9746V13.8211C3.91406 13.8673 3.92331 13.9136 3.94182 13.957C3.9774 14.0438 4.04713 14.1135 4.13466 14.1498C4.17736 14.1669 4.22361 14.1769 4.26987 14.1769H7.1163C7.3127 14.1769 7.4721 14.0175 7.4721 13.8211C7.4721 13.6247 7.3127 13.4653 7.1163 13.4653H5.12878L7.3675 11.2265C7.50697 11.0871 7.50697 10.8622 7.36821 10.7227Z"
        fill="#222223"
      />
      <path
        d="M13.6565 4.2414C13.6131 4.22432 13.5669 4.21436 13.5206 4.21436H10.6742C10.4778 4.21436 10.3184 4.37376 10.3184 4.57016C10.3184 4.76656 10.4778 4.92596 10.6742 4.92596H12.6617L10.423 7.16468C10.2842 7.30345 10.2842 7.52903 10.423 7.66779C10.492 7.73753 10.5831 7.7724 10.6742 7.7724C10.7652 7.7724 10.8563 7.73753 10.9261 7.6685L13.1648 5.42907V7.41659C13.1648 7.613 13.3242 7.7724 13.5206 7.7724C13.717 7.7724 13.8764 7.613 13.8764 7.41659V4.57016C13.8764 4.52391 13.8672 4.47765 13.8486 4.43424C13.8131 4.34743 13.7433 4.27769 13.6565 4.2414Z"
        fill="#222223"
      />
      <path
        d="M14.2324 2.07959H3.55832C2.57772 2.07959 1.7793 2.8773 1.7793 3.85861V14.5327C1.7793 15.514 2.57772 16.3118 3.55832 16.3118H14.2324C15.213 16.3118 16.0115 15.514 16.0115 14.5327V3.85861C16.0115 2.8773 15.213 2.07959 14.2324 2.07959ZM15.2999 14.5327C15.2999 15.1212 14.8209 15.6001 14.2324 15.6001H3.55832C2.96982 15.6001 2.49091 15.1212 2.49091 14.5327V3.85861C2.49091 3.27011 2.96982 2.7912 3.55832 2.7912H14.2324C14.8209 2.7912 15.2999 3.27011 15.2999 3.85861V14.5327Z"
        fill="#222223"
      />
    </g>
  </svg>
);

export const bathroomIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <g opacity="0.8" clip-path="url(#clip0_50_5252)">
      <path
        d="M13.6271 17.3005H4.13898C1.95965 17.3005 0.185547 15.5264 0.185547 13.3471V9.78906H17.5804V13.3471C17.5804 15.5264 15.8063 17.3005 13.6271 17.3005ZM0.976175 10.5797V13.3471C0.976175 15.0915 2.39456 16.5098 4.13898 16.5098H13.6271C15.3715 16.5098 16.7897 15.0915 16.7897 13.3471V10.5797H0.976175Z"
        fill="black"
      />
      <path d="M4.92969 16.9048H5.72032V18.0908H4.92969V16.9048Z" fill="black" />
      <path d="M12.0449 16.9048H12.8355V18.0908H12.0449V16.9048Z" fill="black" />
      <path
        d="M3.34727 10.1843H2.55664V3.0682C2.55664 1.54109 3.79695 0.300781 5.32405 0.300781C6.85102 0.300781 8.09132 1.54109 8.09132 3.0682V3.46344H7.3007V3.0682C7.3007 1.976 6.41611 1.09141 5.32405 1.09141C4.23186 1.09141 3.34727 1.976 3.34727 3.0682V10.1843Z"
        fill="black"
      />
      <path
        d="M10.4644 6.6264H4.92969V5.83563C4.92969 4.30866 6.16999 3.06836 7.6971 3.06836C9.22407 3.06836 10.4644 4.30866 10.4644 5.83563V6.6264ZM5.72032 5.83563H9.67374C9.67374 4.74358 8.78916 3.85899 7.6971 3.85899C6.6049 3.85899 5.72032 4.74358 5.72032 5.83563Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_50_5252">
        <rect width="17.7902" height="17.7902" fill="white" transform="translate(0 0.300781)" />
      </clipPath>
    </defs>
  </svg>
);

export const bedroomIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19" fill="none">
    <g opacity="0.8" clip-path="url(#clip0_50_5262)">
      <path
        d="M20.3311 7.46276H19.9615V5.23898C19.9603 4.47178 19.1334 3.85002 18.1132 3.84912H5.54485C4.5246 3.85002 3.69777 4.47178 3.69657 5.23898V7.46276H3.32691C1.49046 7.46443 0.00221794 8.58354 0 9.96451V13.8561H0.739313V15.8019H2.5876V13.8561H21.0704V15.8019H22.9187V13.8561H23.658V9.96451C23.6558 8.58354 22.1676 7.46443 20.3311 7.46276ZM4.43588 5.23898C4.43588 4.77842 4.93238 4.40507 5.54485 4.40507H18.1132C18.7257 4.40507 19.2221 4.77842 19.2221 5.23898V7.46276H18.1132V6.35087C18.1132 5.8903 17.6167 5.51695 17.0042 5.51695H13.6773C13.0648 5.51695 12.5683 5.8903 12.5683 6.35087V7.46276H11.0897V6.35087C11.0897 5.8903 10.5932 5.51695 9.98073 5.51695H6.65382C6.04135 5.51695 5.54485 5.8903 5.54485 6.35087V7.46276H4.43588V5.23898ZM17.3739 6.35087V7.46276H13.3076V6.35087C13.3076 6.19736 13.4732 6.0729 13.6773 6.0729H17.0042C17.2084 6.0729 17.3739 6.19736 17.3739 6.35087ZM10.3504 6.35087V7.46276H6.28416V6.35087C6.28416 6.19736 6.44968 6.0729 6.65382 6.0729H9.98073C10.1849 6.0729 10.3504 6.19736 10.3504 6.35087ZM1.84828 15.246H1.47863V13.8561H1.84828V15.246ZM22.1794 15.246H21.8097V13.8561H22.1794V15.246ZM22.9187 13.3002H0.739313V11.9103H22.9187V13.3002ZM22.9187 11.3544H0.739313V9.96451C0.740931 8.89039 1.89851 8.01992 3.32691 8.0187H20.3311C21.7595 8.01992 22.9171 8.89039 22.9187 9.96451V11.3544Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_50_5262">
        <rect width="23.658" height="17.7902" fill="white" transform="translate(0 0.930176)" />
      </clipPath>
    </defs>
  </svg>
);

export const shortenString = (str, length) => {
  if (str.length > length) return str.slice(0, length - 3) + '...';
  return str;
};
