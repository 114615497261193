import React from 'react';

export const sendAnalyticsEvent = (category = 'button_click', label = 'button_label') => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'click', {
      event_category: category,
      event_label: label,
    });
  }
};

export const getLinkLabelFromChildren = children => {
  if (typeof children === 'string') {
    return children; // If children is a string, return it directly
  } else if (React.isValidElement(children) && typeof children.props.children === 'string') {
    return children.props.children; // If children is a React element with string children, return them
  } else {
    // If children is a complex React element, traverse its children to find text content
    let textContent = '';
    React.Children.forEach(children, child => {
      if (typeof child === 'string') {
        textContent += child;
      } else if (React.isValidElement(child) && typeof child.props.children === 'string') {
        textContent += child.props.children;
      }
    });
    return textContent;
  }
};
