/**
 * Transaction process graph for plain inquiries:
 *   - default-inquiry
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // A customer can initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE_WITHOUT_PAYMENT: 'transition/inquire-without-payment',

  MOVE_TO_STAGE_2_BY_CUSTOMER: 'transition/move-to-stage-2-by-customer',
  UPDATE_DATA_STAGE_1_BY_CUSTOMER: 'transition/update-data-stage-1-by-customer',
  UPDATE_DATA_STAGE_1_BY_PROVIDER: 'transition/update-data-stage-1-by-provider',

  MOVE_TO_STAGE_3_BY_CUSTOMER: 'transition/move-to-stage-3-by-customer',
  UPDATE_DATA_STAGE_2_BY_CUSTOMER: 'transition/update-data-stage-2-by-customer',
  UPDATE_DATA_STAGE_2_BY_PROVIDER: 'transition/update-data-stage-2-by-provider',

  MOVE_TO_STAGE_4_BY_CUSTOMER: 'transition/move-to-stage-4-by-customer',
  UPDATE_DATA_STAGE_3_BY_CUSTOMER: 'transition/update-data-stage-3-by-customer',
  UPDATE_DATA_STAGE_3_BY_PROVIDER: 'transition/update-data-stage-3-by-provider',

  MOVE_TO_STAGE_5_BY_CUSTOMER: 'transition/move-to-stage-5-by-customer',
  UPDATE_DATA_STAGE_4_BY_CUSTOMER: 'transition/update-data-stage-4-by-customer',
  UPDATE_DATA_STAGE_4_BY_PROVIDER: 'transition/update-data-stage-4-by-provider',

  MOVE_TO_STAGE_6_BY_CUSTOMER: 'transition/move-to-stage-6-by-customer',
  UPDATE_DATA_STAGE_5_BY_CUSTOMER: 'transition/update-data-stage-5-by-customer',
  UPDATE_DATA_STAGE_5_BY_PROVIDER: 'transition/update-data-stage-5-by-provider',

  MOVE_TO_STAGE_7_BY_CUSTOMER: 'transition/move-to-stage-7-by-customer',
  UPDATE_DATA_STAGE_6_BY_CUSTOMER: 'transition/update-data-stage-6-by-customer',
  UPDATE_DATA_STAGE_6_BY_PROVIDER: 'transition/update-data-stage-6-by-provider',

  MOVE_TO_STAGE_8_BY_CUSTOMER: 'transition/move-to-stage-8-by-customer',
  UPDATE_DATA_STAGE_7_BY_CUSTOMER: 'transition/update-data-stage-7-by-customer',
  UPDATE_DATA_STAGE_7_BY_PROVIDER: 'transition/update-data-stage-7-by-provider',

  MOVE_TO_STAGE_9_BY_CUSTOMER: 'transition/move-to-stage-9-by-customer',
  UPDATE_DATA_STAGE_8_BY_CUSTOMER: 'transition/update-data-stage-8-by-customer',
  UPDATE_DATA_STAGE_8_BY_PROVIDER: 'transition/update-data-stage-8-by-provider',

  MOVE_TO_STAGE_10_BY_CUSTOMER: 'transition/move-to-stage-10-by-customer',
  UPDATE_DATA_STAGE_9_BY_CUSTOMER: 'transition/update-data-stage-9-by-customer',
  UPDATE_DATA_STAGE_9_BY_PROVIDER: 'transition/update-data-stage-9-by-provider',

  MOVE_TO_STAGE_11_BY_CUSTOMER: 'transition/move-to-stage-11-by-customer',
  UPDATE_DATA_STAGE_10_BY_CUSTOMER: 'transition/update-data-stage-10-by-customer',
  UPDATE_DATA_STAGE_10_BY_PROVIDER: 'transition/update-data-stage-10-by-provider',

  MOVE_TO_STAGE_12_BY_CUSTOMER: 'transition/move-to-stage-12-by-customer',
  UPDATE_DATA_STAGE_11_BY_CUSTOMER: 'transition/update-data-stage-11-by-customer',
  UPDATE_DATA_STAGE_11_BY_PROVIDER: 'transition/update-data-stage-11-by-provider',

  MOVE_TO_STAGE_13_BY_CUSTOMER: 'transition/move-to-stage-13-by-customer',
  UPDATE_DATA_STAGE_12_BY_CUSTOMER: 'transition/update-data-stage-12-by-customer',
  UPDATE_DATA_STAGE_12_BY_PROVIDER: 'transition/update-data-stage-12-by-provider',

  MOVE_TO_STAGE_14_BY_CUSTOMER: 'transition/move-to-stage-14-by-customer',
  UPDATE_DATA_STAGE_13_BY_CUSTOMER: 'transition/update-data-stage-13-by-customer',
  UPDATE_DATA_STAGE_13_BY_PROVIDER: 'transition/update-data-stage-13-by-provider',

  MOVE_TO_COMPLETED: 'transition/move-to-completed',
  UPDATE_DATA_STAGE_14_BY_CUSTOMER: 'transition/update-data-stage-14-by-customer',
  UPDATE_DATA_STAGE_14_BY_PROVIDER: 'transition/update-data-stage-14-by-provider',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  STAGE_1: 'stage-1',
  STAGE_2: 'stage-2',
  STAGE_3: 'stage-3',
  STAGE_4: 'stage-4',
  STAGE_5: 'stage-5',
  STAGE_6: 'stage-6',
  STAGE_7: 'stage-7',
  STAGE_8: 'stage-8',
  STAGE_9: 'stage-9',
  STAGE_10: 'stage-10',
  STAGE_11: 'stage-11',
  STAGE_12: 'stage-12',
  STAGE_13: 'stage-13',
  STAGE_14: 'stage-14',
  COMPLETED: 'completed',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-inquiry/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE_WITHOUT_PAYMENT]: states.STAGE_1,
      },
    },

    [states.STAGE_1]: {
      on: {
        [transitions.MOVE_TO_STAGE_2_BY_CUSTOMER]: states.STAGE_2,
        [transitions.UPDATE_DATA_STAGE_1_BY_CUSTOMER]: states.STAGE_1,
        [transitions.UPDATE_DATA_STAGE_1_BY_PROVIDER]: states.STAGE_1,
      },
    },

    [states.STAGE_2]: {
      on: {
        [transitions.MOVE_TO_STAGE_3_BY_CUSTOMER]: states.STAGE_3,
        [transitions.UPDATE_DATA_STAGE_2_BY_CUSTOMER]: states.STAGE_2,
        [transitions.UPDATE_DATA_STAGE_2_BY_PROVIDER]: states.STAGE_2,
      },
    },

    [states.STAGE_3]: {
      on: {
        [transitions.MOVE_TO_STAGE_4_BY_CUSTOMER]: states.STAGE_4,
        [transitions.UPDATE_DATA_STAGE_3_BY_CUSTOMER]: states.STAGE_3,
        [transitions.UPDATE_DATA_STAGE_3_BY_PROVIDER]: states.STAGE_3,
      },
    },

    [states.STAGE_4]: {
      on: {
        [transitions.MOVE_TO_STAGE_5_BY_CUSTOMER]: states.STAGE_5,
        [transitions.UPDATE_DATA_STAGE_4_BY_CUSTOMER]: states.STAGE_4,
        [transitions.UPDATE_DATA_STAGE_4_BY_PROVIDER]: states.STAGE_4,
      },
    },
    [states.STAGE_5]: {
      on: {
        [transitions.MOVE_TO_STAGE_6_BY_CUSTOMER]: states.STAGE_6,
        [transitions.UPDATE_DATA_STAGE_5_BY_CUSTOMER]: states.STAGE_5,
        [transitions.UPDATE_DATA_STAGE_5_BY_PROVIDER]: states.STAGE_5,
      },
    },

    [states.STAGE_6]: {
      on: {
        [transitions.MOVE_TO_STAGE_7_BY_CUSTOMER]: states.STAGE_7,
        [transitions.UPDATE_DATA_STAGE_6_BY_CUSTOMER]: states.STAGE_6,
        [transitions.UPDATE_DATA_STAGE_6_BY_PROVIDER]: states.STAGE_6,
      },
    },

    [states.STAGE_7]: {
      on: {
        [transitions.MOVE_TO_STAGE_8_BY_CUSTOMER]: states.STAGE_8,
        [transitions.UPDATE_DATA_STAGE_7_BY_CUSTOMER]: states.STAGE_7,
        [transitions.UPDATE_DATA_STAGE_7_BY_PROVIDER]: states.STAGE_7,
      },
    },

    [states.STAGE_8]: {
      on: {
        [transitions.MOVE_TO_STAGE_9_BY_CUSTOMER]: states.STAGE_9,
        [transitions.UPDATE_DATA_STAGE_8_BY_CUSTOMER]: states.STAGE_8,
        [transitions.UPDATE_DATA_STAGE_8_BY_PROVIDER]: states.STAGE_8,
      },
    },

    [states.STAGE_9]: {
      on: {
        [transitions.MOVE_TO_STAGE_10_BY_CUSTOMER]: states.STAGE_10,
        [transitions.UPDATE_DATA_STAGE_9_BY_CUSTOMER]: states.STAGE_9,
        [transitions.UPDATE_DATA_STAGE_9_BY_PROVIDER]: states.STAGE_9,
      },
    },

    [states.STAGE_10]: {
      on: {
        [transitions.MOVE_TO_STAGE_11_BY_CUSTOMER]: states.STAGE_11,
        [transitions.UPDATE_DATA_STAGE_10_BY_CUSTOMER]: states.STAGE_10,
        [transitions.UPDATE_DATA_STAGE_10_BY_PROVIDER]: states.STAGE_10,
      },
    },

    [states.STAGE_11]: {
      on: {
        [transitions.MOVE_TO_STAGE_12_BY_CUSTOMER]: states.STAGE_12,
        [transitions.UPDATE_DATA_STAGE_11_BY_CUSTOMER]: states.STAGE_11,
        [transitions.UPDATE_DATA_STAGE_11_BY_PROVIDER]: states.STAGE_11,
      },
    },

    [states.STAGE_12]: {
      on: {
        [transitions.MOVE_TO_STAGE_13_BY_CUSTOMER]: states.STAGE_13,
        [transitions.UPDATE_DATA_STAGE_12_BY_CUSTOMER]: states.STAGE_12,
        [transitions.UPDATE_DATA_STAGE_12_BY_PROVIDER]: states.STAGE_12,
      },
    },

    [states.STAGE_13]: {
      on: {
        [transitions.MOVE_TO_STAGE_14_BY_CUSTOMER]: states.STAGE_14,
        [transitions.UPDATE_DATA_STAGE_13_BY_CUSTOMER]: states.STAGE_13,
        [transitions.UPDATE_DATA_STAGE_13_BY_PROVIDER]: states.STAGE_13,
      },
    },

    [states.STAGE_14]: {
      on: {
        [transitions.MOVE_TO_COMPLETED]: states.COMPLETED,
        [transitions.UPDATE_DATA_STAGE_14_BY_CUSTOMER]: states.STAGE_14,
        [transitions.UPDATE_DATA_STAGE_14_BY_PROVIDER]: states.STAGE_14,
      },
    },

    [states.COMPLETED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return false; //[transitions.INQUIRE_WITHOUT_PAYMENT].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
// NOTE: this functions is added just for the sake of consistency
export const isCustomerReview = transition => {
  return false;
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
// NOTE: this functions is added just for the sake of consistency
export const isProviderReview = transition => {
  return false;
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
// NOTE: this functions is added just for the sake of consistency
export const isPrivileged = transition => {
  return false;
};

// Check when transaction is completed (booking over)
// NOTE: this functions is added just for the sake of consistency
export const isCompleted = transition => {
  return false;
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
// NOTE: this functions is added just for the sake of consistency
export const isRefunded = transition => {
  return false;
};

// NOTE: this functions is added just for the sake of consistency
// We don't know if inquiry is on-going or complete
export const statesNeedingProviderAttention = [];
