import React, { useState } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import css from './LikeDislikeButton.module.css';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { handleLike, handleDislike } from './utils';
import { post } from '../../util/api';
import { createSlug } from '../../util/urlHelpers';
import heart from './heart.png';

const LikeDislikeButtonComponent = props => {
  const { currentUser, currentImage, onUpdateProfile, listing, redirectId, redirectSlug } = props;
  const redirectInfo = {
    id: listing?.id?.uuid,
    slug: listing?.attributes?.title && createSlug(listing.attributes.title || ''),
  };
  const currentImageId = currentImage?.id?.uuid;
  const currentImageUrl = currentImage?.attributes?.variants?.default?.url;
  const currentImageObj = {
    id: currentImageId,
    url: currentImageUrl,
  };
  const reactionsMap = listing?.attributes?.publicData?.reactionsMap || {};
  const savedCurrentLikes = reactionsMap[currentImageId]?.likesCount;
  const savedCurrentDislikes = reactionsMap[currentImageId]?.dislikesCount;

  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [currentLikes, setCurrentLikes] = useState(savedCurrentLikes || 0);
  const [currentDislikes, setCurrentDislikes] = useState(savedCurrentDislikes || 0);

  if (!currentImageId) {
    return null;
  }

  const isLiked = currentUser?.attributes?.profile?.publicData?.imagesLiked?.find(
    i => i?.id === currentImageId
  );
  const isDisliked = currentUser?.attributes?.profile?.publicData?.imagesDisliked?.find(
    i => i?.id === currentImageId
  );

  const handleLikeAction = async e => {
    e.preventDefault();
    e.stopPropagation();
    if (!currentUser?.id?.uuid && typeof window !== 'undefined') {
      window.location.href = '/signup';
      return;
    }
    if (updateInProgress) return;
    setUpdateInProgress(true);
    if (isDisliked) {
      //remove the dislike, so we can switch to a like
      await handleDislike(
        currentUser?.attributes?.profile?.publicData?.imagesDisliked,
        onUpdateProfile,
        currentImageObj,
        redirectInfo
      );
    }
    await handleLike(
      currentUser?.attributes?.profile?.publicData?.imagesLiked,
      onUpdateProfile,
      currentImageObj,
      redirectInfo
    );

    const newReactionsMap = {
      ...reactionsMap,
      [currentImageId]: {
        likesCount: currentLikes + (isLiked ? -1 : 1),
        dislikesCount: currentDislikes + +(isDisliked ? -1 : 0),
      },
    };

    await post('/api/integration/update-listing', {
      listingId: listing?.id?.uuid,
      publicData: {
        reactionsMap: newReactionsMap,
      },
    });
    setCurrentLikes(currentLikes + (isLiked ? -1 : 1));
    setCurrentDislikes(currentDislikes + (isDisliked ? -1 : 0));
    setUpdateInProgress(false);
    //redirect maybe
    if (redirectId && redirectSlug && typeof window !== 'undefined') {
      window.location.href = `${window.location.origin}/l/${redirectSlug}/${redirectId}`;
    }
  };

  const handleDislikeAction = async e => {
    e.preventDefault();
    e.stopPropagation();
    if (!currentUser?.id?.uuid && typeof window !== 'undefined') {
      window.location.href = '/signup';
      return;
    }
    if (updateInProgress) return;
    setUpdateInProgress(true);
    if (isLiked) {
      // remove the like so we can switch to a dislike
      await handleLike(
        currentUser?.attributes?.profile?.publicData?.imagesLiked,
        onUpdateProfile,
        currentImageObj,
        redirectInfo
      );
    }
    await handleDislike(
      currentUser?.attributes?.profile?.publicData?.imagesDisliked,
      onUpdateProfile,
      currentImageObj,
      redirectInfo
    );

    const newReactionsMap = {
      ...reactionsMap,
      [currentImageId]: {
        likesCount: currentLikes + (isLiked ? -1 : 0),
        dislikesCount: currentDislikes + (isDisliked ? -1 : 1),
      },
    };

    await post('/api/integration/update-listing', {
      listingId: listing?.id?.uuid,
      publicData: {
        reactionsMap: newReactionsMap,
      },
    });

    setCurrentLikes(currentLikes + (isLiked ? -1 : 0));
    setCurrentDislikes(currentDislikes + (isDisliked ? -1 : 1));

    setUpdateInProgress(false);
    //redirect maybe
    if (redirectId && redirectSlug && typeof window !== 'undefined') {
      window.location.href = `${window.location.origin}/l/${redirectSlug}/${redirectId}`;
    }
  };

  return (
    <div
      id={currentImageId || 'dummyId'}
      className={`${css.wrapper} ${isLiked && css.likedWrapper}`}
      onClick={handleLikeAction}
    >
      <img src={heart} className={css.likeIcon} />
      <div className={css.likeCount}>{currentLikes >= 0 ? currentLikes : 0}</div>
      {/* <ThumbDownIcon
        onClick={handleDislikeAction}
        className={`${css.dislikeButton} ${isDisliked && css.dislikeActive}`}
      />
      <div className={css.dislikeCount}>{currentDislikes >= 0 ? currentDislikes : 0}</div> */}
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const LikeDislikeButton = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LikeDislikeButtonComponent);

export default LikeDislikeButton;
