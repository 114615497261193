export const handleLike = async (
  currentLikes = [],
  onUpdateProfile,
  currentImageObj,
  redirectInfo
) => {
  try {
    const updatedCurrentLikes = currentLikes.find(i => i?.id === currentImageObj?.id)
      ? currentLikes.filter(i => i?.id !== currentImageObj?.id)
      : [...currentLikes, { ...currentImageObj, redirectInfo }];
    await onUpdateProfile({
      publicData: {
        imagesLiked: updatedCurrentLikes,
      },
    });
  } catch (e) {
    console.log('ERROR ON LIKING IMAGE', e);
  }
};

export const handleDislike = async (
  currentDislikes = [],
  onUpdateProfile,
  currentImageObj,
  redirectInfo
) => {
  try {
    const updatedCurrentDislikes = currentDislikes.find(i => i?.id === currentImageObj?.id)
      ? currentDislikes.filter(i => i?.id !== currentImageObj?.id)
      : [...currentDislikes, { ...currentImageObj, redirectInfo }];
    await onUpdateProfile({
      publicData: {
        imagesDisliked: updatedCurrentDislikes,
      },
    });
  } catch (e) {
    console.log('ERROR ON DISLIKING IMAGE', e);
  }
};
